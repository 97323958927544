import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import './blog-post.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const BlogPostTemplate = ({
  data: { previous, next, site, markdownRemark: post },
  location
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`;
  const image = getImage(post.frontmatter.image);

  console.log({ location });

  return (
    <>
      <Navbar />
      <main className="main-post">
        <article
          className="article"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>Published on {post.frontmatter.date}</p>
            {/* <GatsbyImage image={image} alt={post.frontmatter.image_alt} /> */}
            {/* <figcaption style={{ fontSize: "14px" }}>
              Photo Credit:{" "}
              <a href="https://unsplash.com" style={{ textDecoration: "none" }}>
                {post.frontmatter.image_credit_text}
              </a>
            </figcaption> */}
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
        <div className="blog-post-nav">
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image_alt
        image_credit_text
        image {
          childImageSharp {
            gatsbyImageData(height: 400, width: 1000)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
